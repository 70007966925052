import React from 'react'
import Dashboard from '../assets/dashboard.png'

const About = () => {
  return (
    <div id='about' className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Dashboard} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#F6850C] font-bold '>INTRODUCING THE GRAVY APP</p>
          <h1 className='md:text-2xl sm:text-3xl text-2xl font-bold py-2'>          Your ultimate sidekick for technicians seeking unparalleled efficiency and inspiration.</h1>
          <br/>
          <p>
          Gravy is a revolutionary application designed to transform how technicians track their flagged hours and productivity. Say goodbye to outdated methods and hello to a user-friendly, gamified experience that technicians will love.
          <br/>
          <br/>
          By seamlessly integrating with their phones, Gravy empowers technicians to effortlessly monitor their earnings and progress, providing a clear roadmap for their pay period journey.
          <br/>
          <br/>
          But Gravy doesn't stop there. It's not just about numbers; it's about motivation. With each task completed, technicians unlock exciting tokens and awards, fostering a sense of accomplishment and driving productivity to new heights.
          <br/>
          <br/>
          For service directors, Gravy becomes an invaluable tachometer, offering accurate and actionable data to track and forecast technician performance. Embrace the power of Gravy and discover hidden reserves within your team's potential.
          <br/>
          <br/>
          Gravy: the game-changer that transforms work into a rewarding adventure.
          <br/> Embrace the future of productivity today.
          </p>
          <br/>
          <button className='bg-[#F6850C] text-[#000] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
  )
}

export default About
