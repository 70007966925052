import React from 'react'
// import {
//   FaDribbbleSquare,
//   FaFacebookSquare,
//   FaGithubSquare,
//   FaInstagram,
//   FaTwitterSquare,
// } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300 center'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#F6850C]'>GRAVY</h1>
        <p className='py-2'>The game-changer that transforms work into a rewarding adventure. Embrace the future of productivity today.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <p>© GRAVY 2023</p>
        </div>
        {/* <div className='flex justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            <FaGithubSquare size={30} />
            <FaDribbbleSquare size={30} />
        </div> */}
      </div>
      <div className='flex justify-end mt-6 gap-20'>
    {/* <div>
        <h6 className='font-medium text-gray-400'>Solutions</h6>
        <ul>
            <li className='py-2 text-sm'>Analytics</li>
            <li className='py-2 text-sm'>Marketing</li>
            <li className='py-2 text-sm'>Commerce</li>
            <li className='py-2 text-sm'>Insights</li>
        </ul>
    </div> */}
    {/* <div>
        <h6 className='font-medium text-gray-400'>Support</h6>
        <ul>
            <li className='py-2 text-sm'>Pricing</li>
            <li className='py-2 text-sm'>Documentation</li>
            <li className='py-2 text-sm'>Guides</li>
            <li className='py-2 text-sm'>API Status</li>
        </ul>
    </div> */}
    <div>
        <ul>
            <li className='py-2 text-sm'>About</li>
            <li className='py-2 text-sm'>Contact</li>
            <li className='py-2 text-sm'>Privacy</li>
            <li className='py-2 text-sm'>Terms</li>
        </ul>
    </div>
    {/* <div>
        <h6 className='font-medium text-gray-400'>Legal</h6>
        <ul>
            <li className='py-2 text-sm'>Privacy</li>
            <li className='py-2 text-sm'>Terms</li>
        </ul>
    </div> */}
      </div>
    </div>
  )
}

export default Footer
