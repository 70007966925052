import React from 'react'
// import Typed from 'react-typed'

const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#F6850C] font-bold p-2'>
          Track Your Stack
        </p>
        <h1 className='md:text-4xl sm:text-6xl text-4xl font-bold md:py-6'>
          Drive efficiency and motivation with Gravy — <br/> An innovative application for tracking <br/> and boosting productivity.
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-3xl sm:text-4xl text-xl font-bold py-4'>
            Unlocking the Potential of Technicians:
          </p>
          {/* <Typed
          className='md:text-3xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Productivity', 'Engagement', 'Tracking']}
            typeSpeed={150}
            backSpeed={180}
            loop
          /> */}
        </div>
        <p className='md:text-2xl text-xl font-bold text-[#8F8F8F]'>Elevating performance and driving success.</p>
        <a href='#learnMore'><button className='bg-[#F6850C] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Learn More</button></a>
      </div>
    </div>
  );
};

export default Hero
