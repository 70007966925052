import React from 'react';
import efficiency from '../assets/efficiency.png'
import engagement from '../assets/engagement.png'
import analytics from '../assets/analytics.png'
import integration from '../assets/integration.png'

const Cards = () => {
  return (
    <div id='learnMore' className='w-full py-[10rem] px-4 bg-white'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-6'>
          <div className='w-full shadow-md flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-2rem] bg-white' src={efficiency} alt="/" />
              <h2 className='text-2xl font-bold text-center py-4'>Automated Efficiency Tracking</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 mx-6 mt-2'>Automatically track and analyze technician performance, providing real-time insights into productivity levels, flagged hours, and overall efficiency. Say goodbye to manual tracking methods and embrace seamless automation.</p>
              </div>
          </div>
          <div className='w-full shadow-md flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-2rem] bg-white' src={engagement} alt="/" />
              <h2 className='text-2xl font-bold text-center py-4'>Engaging Experience</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 mx-6 mt-2'>Transform productivity tracking into an enjoyable and rewarding experience. Through its user interface, technicians are motivated to achieve higher levels of productivity, earn virtual rewards, and unlock exciting milestones, fostering a sense of accomplishment.</p>
              </div>
          </div>
          <div className='w-full shadow-md flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-2rem] bg-white' src={analytics} alt="/" />
              <h2 className='text-2xl font-bold text-center py-4'>Comprehensive Data & Analytics</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 mx-6 mt-2'>Gain valuable insight into individual technician performance, departmental trends, and overall productivity metrics. Gravy provides service directors with accurate and usable data enabling informed decision-making and targeted improvements.</p>
              </div>
          </div>
          <div className='w-full shadow-md flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-2rem] bg-white' src={integration} alt="/" />
              <h2 className='text-2xl font-bold text-center py-4'>Seamless Integration & Easy Implementation</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 mx-6 mt-2'>Designed with simplicity in mind, Gravy seamlessly integrates into technicians' workflow, utilizing their smartphones as the primary platform. Requiring minimal setup and runs autonomously, creating enhanced productivity tracking.</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Cards;
