import React from 'react'

const Contact = () => {
  return (
    <div id='contact' className='w-full py-16 text-white px-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-2 pt-[100px]'>
          <h1 className='text-[#F6850C] md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Connect with us.
          </h1>
          <p>Our support team is here to answer any questions you may have.</p>
        </div>
        <div className='my-4'>
          {/* Name input */}
          <div className='my-2'>
            <input
              className='p-3 w-full rounded-md text-black'
              type='text'
              placeholder='name'
            />
          </div>

          {/* Email input */}
          <div className='my-2'>
            <input
              className='p-3 w-full rounded-md text-black'
              type='email'
              placeholder='email'
            />
          </div>

          {/* Message input */}
          <div className='my-2'>
            <textarea
              className='p-3 w-full rounded-md text-black'
              rows='4'
              placeholder='message'
            ></textarea>
          </div>

          {/* "Learn More" button */}
          <div className='my-6'>
            <button className='bg-[#F6850C] text-black rounded-md font-medium w-[200px] mx-auto py-3'>
              Send
            </button>
          </div>

          {/* Privacy Notice */}
          <div className='my-6'>
            <p className='text-[#8F8F8F]'>
              We care about the protection of your data. <br/>Read our{' '}
              <span className='text-[#F6850C]'>Privacy Policy.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
