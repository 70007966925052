import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import logo from '../assets/gravy-logo.png'

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav)
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      {/* <img className='w-40 mx-auto mt-[-2rem] bg-white' src={logo} alt="/" /> */}
      <h1 className='w-full text-3xl font-bold text-[#fff]'>GRAVY</h1>
      <ul className='hidden md:flex text-[#8F8F8F]'>
        {/* <li className='p-4'>Home</li> */}
        <li className='p-4'><a href='#about'>About</a></li>
        <li className='p-4'><a href='#contact'>Contact</a></li>
        <li className='p-4'><button className='text-[#F6850C]'>Login</button></li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-[#F6850C] m-4'>GRAVY</h1>
          <li className='p-4 border-b border-gray-600'>Home</li>
          <li className='p-4 border-b border-gray-600'>About</li>
          <li className='p-4 border-b border-gray-600'>Login</li>
          <li className='p-4'>Contact</li>
      </ul>
    </div>
  )
}

export default Navbar
